import iconSet from 'quasar/icon-set/fontawesome-v6'
import lang from 'quasar/lang/en-US'
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'
import 'quasar/dist/quasar.css'

export default {
  config: {},
  plugins: {},
  iconSet: iconSet,
  lang: lang
}
